<template>
  <section style="margin: 0 24px">
    <!-- <tableHeader>
      <a-input class="sec-input" allowClear :maxlength="20" v-model:value="searchVal" placeholder="请输入姓名或账号">
        <template #prefix>
          <img src="@/assets/svg/search.svg" />
        </template>
      </a-input>
      <a-button class="search-btn basic-btn" @click="searchList">查询</a-button>
    </tableHeader> -->
    <section class="form-style">
      <a-form layout="inline">
        <a-form-item label="" @click="clickFocus">
          <a-input
            class="form-input"
            allowClear
            :maxlength="20"
            v-model:value="searchVal"
            placeholder="请输入姓名或账号"
            ref="inputbox"
          >
            <template #prefix>
              <img src="@/assets/svg/search.svg" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="form-btns">
          <a-button class="form-btn" @click="searchList">查询</a-button>
        </a-form-item>
      </a-form>
    </section>
    <section class="table-style">
      <div class="table-head">
        <span class="title">指纹列表</span>
      </div>
      <a-table
        :columns="fingerColumns"
        :dataSource="dataSource"
        :pagination="pagination"
        @change="handleTableChange"
        :loading="tableLoading"
        ref="tableRef"
        :scroll="{ x: true }"
      >
        <template #id1="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
        <template #number="{ text }">
          <span :title="text">{{ text }}</span>
        </template>
        <template #staffName="{ text }">
          <span :title="text">{{ text ?? '-' }}</span></template
        >
        <template #departmentName="{ text }">
          <div class="dept-name text-ellipsis" v-if="text?.length < 10">{{ text }}</div>
          <a-tooltip v-else>
            <template #title>{{ text }}</template>
            <div class="dept-name text-ellipsis">{{ text }}</div>
          </a-tooltip>
        </template>
        <template #mobile="{ text }">
          <span :title="text">{{ text ?? '-' }}</span></template
        >
        <!-- <template #action><a @click="openModal">查看</a></template> -->
        <template #action="{text,record}">
          <a @click="delFinger(record)" style="color: rgb(24, 144, 255);" v-btn="'PC_FINGERPRINT_MENU_DEL'">删除</a>
        </template>
      </a-table>
    </section>
  </section>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs, createVNode } from 'vue'
import { fingerColumns } from './columns'
import tableHeader from '@/views/components/tableHeader'
import { getFingerPrintList, delFingerPrintList } from '@/apis/businessManage'
import { paginationParams } from '@/utils/constData'
import { cmsNotice, setTablePageFooterStyle } from '@/utils/utils'
import { Modal } from 'ant-design-vue'
import { DownOutlined, ExclamationOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    tableHeader
  },
  setup() {
    const inputbox = ref(null)
    const tableRef = ref()
    const state = reactive({
      searchVal: undefined,
      fingerColumns,
      timeSort: '',
      pagination: {
        ...paginationParams
      },
      dataSource: [],
      visible: false,
      currentData: undefined,
      tableLoading: true,
      currentNum: 1
    })

    const getList = async () => {
      state.tableLoading = true
      const params = {
        search: state.searchVal,
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize,
        timeSort: state.timeSort
      }
      const res = await getFingerPrintList(params)
      state.dataSource = res.data
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.tableLoading = false
      setTablePageFooterStyle(tableRef)
    }
    const openModal = (type, record) => {
      if (type === 'delete') {
        Modal.confirm({
          title: '确认提示',
          content: '是否删除该指纹，指纹删除后将无法恢复，是否确认删除？',
          centered: true,
          confirmLoading: state.loading,
          icon: createVNode(ExclamationOutlined),
          onOk: async () => {
            // const res = await deleteRole({ id: current.id })
            // if (res.success) {
            //   cmsNotice('success', '指纹删除成功')
            //   state.pagination.total %
            //     (state.pagination.current * state.pagination.pageSize - state.pagination.pageSize) ===
            //     1 && (state.pagination.current -= 1)
            //   getList()
            // }
          }
        })
      }
      // state.visible = true
    }
    const delFinger = record => {
      console.log('record', record)
      Modal.confirm({
        title: '确认提示',
        width: '500px',
        content: '是否删除该指纹，指纹删除后将无法恢复，是否确认删除？',
        centered: true,
        // confirmLoading: loading.value,
        icon: createVNode(ExclamationOutlined),
        onOk: async () => {
          const params = {
            staffId: record.staffId,
            type: 2
          }
          const res = await delFingerPrintList(params)
          if (res.success) {
            cmsNotice('success', '删除成功')
            getList()
          }
        }
      })
    }
    const handleTableChange = ({ current, pageSize }, filters, { order }) => {
      // 排序
      state.timeSort = ''
      if (order == 'ascend') {
        state.timeSort = 1
      }
      state.dataSource = []
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      getList()
    }
    const searchList = () => {
      // 清空前后空格
      if (state.searchVal) {
        state.searchVal = state.searchVal.replace(/\s*/g, '')
      }
      state.pagination.current = state.searchVal ? 1 : state.currentNum
      getList()
    }
    // 搜索框点击
    const clickFocus = () => {
      inputbox.value.focus()
    }

    onMounted(() => {
      getList()
    })

    return {
      ...toRefs(state),
      searchList,
      openModal,
      delFinger,
      handleTableChange,
      tableRef,
      clickFocus,
      inputbox
    }
  }
})
</script>

<style lang="less" scoped>
.dept-name {
  max-width: 140px;
  font-size: 14px;
  color: rgba(0,0,0,0.65);
}
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
