export const fingerColumns = [
  {
    title: '序号',
    dataIndex: 'id1',
    key: 'id1',
    align: 'left',
    slots: { customRender: 'id1' }
    // width: 100
  },
  // {
  //   title: '设备编号',
  //   dataIndex: 'number',
  //   key: 'number',
  //   align: 'left',
  //   ellipsis: true,
  //   slots: { customRender: 'number' },
  //   width: 100
  // },
  {
    title: '用户姓名',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'staffName' }
    // width: 100
  },
  {
    title: '部门',
    dataIndex: 'departmentName',
    key: 'departmentName',
    align: 'left',
    slots: { customRender: 'departmentName' }
  },
  {
    title: '账号',
    dataIndex: 'mobile',
    key: 'mobile',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'mobile' }
    // width: 100
  },
  {
    title: '录入时间',
    dataIndex: 'enteringTime',
    key: 'enteringTime',
    align: 'left',
    sorter: true
    // width: 200
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    slots: { customRender: 'action' },
    align: 'left',
    width: 100
  }
]
